import React, { useState, useEffect } from 'react'
import Header from '../Components/Header';
import axios from 'axios';
import Config from '../Config.json';
import ls from 'local-storage';
import {Link} from "react-router-dom";
import { FiEye } from 'react-icons/fi';
import FileSaver from 'file-saver';
import { generateExcel } from 'json5-to-table';

const Storage = (props) => {
  const [results, setResults] = useState(false);
  const theRequester = ls.get('user');

  useEffect(() => {
    axios.get(`${Config.APIURL}/getItems/${theRequester}`)
    .then(response => {
        setResults(response.data.items);
    })
    .catch (error => {
      setResults(false);
    })
  }, [theRequester]);

  const selectedCo = () => {
    console.log(results);
    const schema = [
      { title: 'اطلاعات شرکت', path: 'Company', props: [
        { title: 'نام', path: 'Name' },
        { title: 'نوع', path: 'Type' },
        { title: 'شناسه ملی', path: 'NationalCode' },
        { title: 'کدپستی', path: 'PostCode' },
        { title: 'شماره ثبت', path: 'RegisterNumber' },
        { title: 'وضعیت', path: 'Status' },
      ] },
      { title: 'اعضا', path: 'Members', props: [
        [
          { title: 'نام و نام خانوادگی', path: 'FullName' },
          { title: 'کد ملی', path: 'NationalityCode' },
          { title: 'تلفن همراه', path: 'MobileNumber4SMS' },
          { title: 'سمت ها', path: 'Positions', props: [
            [
              { title: 'عنوان', path: 'Title' },
              { title: 'تاریخ شروع', path: 'StartDate' },
              { title: 'تاریخ پایان', path: 'EndDateValidity' },
              { title: 'مدت', path: 'PeriodTime' },
            ]
          ]},
          { title: 'شماره شناسنامه', path: 'IdentityNo' },
          { title: 'تاریخ تولد', path: 'BirthDateSH' },
          { title: 'نام پدر', path: 'FatherNameFA' },
        ]
      ]}
    ];
    generateExcel(results, schema, { writeTo: 'شرکتها.xlsx' });
  }

  return (
    <div className='container'>
        <Header logout={props.logout} />
        <div className='body rounded mt-5 flex flex-col justify-center items-center lg:flex lg:flex-row lg:justify-between lg:items-center '>
          {!results ?
          <div>درحال دریافت</div>
          :
          <div className=' w-full'>
          <button className='btn bg-green-500 text-white rounded w-full my-3 p-2 disabled:bg-gray-100 disabled:text-gray-700 disabled:cursor-not-allowed' id='exportBtn' onClick={selectedCo}>خروجی اکسل</button>
          <table className="table table-hover table-auto border-collapse border border-slate-500 rounded w-full">
            <thead>
              <tr>
                <th className="border p-2">نام شرکت</th>
                <th className="border p-2">شناسه ملی</th>
                <th className="border p-2">شماره ثبت</th>
                <th className="border p-2">وضعیت</th>
                <th className="border p-2">نوع شرکت</th>
                <th className="border p-2">نمایش اطلاعات شرکت</th>
              </tr>
            </thead>
            <tbody>
            {results.map(item => (
            <tr key={item._id} className='hover:bg-gray-100'>
              <td className="border p-2">{item.Company.Name}</td>
              <td className="border p-2">{item.Company.NationalCode}</td>
              <td className="border p-2">{item.Company.RegisterNumber}</td>
              <td className="border p-2">{item.Company.Status}</td>
              <td className="border p-2">{item.Company.Type}</td>
              <td className="border p-2"><Link to={'/' + item._id}><FiEye size="32" className='text-white bg-blue-700 p-2 d-block rounded mx-auto hover:text-blue-700 hover:bg-blue-100' /></Link></td>
            </tr>
            ))
            }
            </tbody>
          </table>
          </div>
          }
        </div>
    </div>
  )
}

export default Storage