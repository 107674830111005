import { BrowserRouter,  Routes,  Route } from "react-router-dom";
import './App.css';
import { useState } from "react";
import ls from 'local-storage';
import Dashboard from './Pages/Dashboard';
import Login from "./Pages/Login";
import Storage from "./Pages/Storage";
import IdPage from "./Pages/Id";

function App() { 
  const [loggedIn, setLoggedIn] = useState(ls.get('loggedIn'));
  const loginUser = async () => {
    ls.set('loggedIn', true);
    setLoggedIn(true);
  }
  const logoutUser = async () => {
    console.log('User Logged out!');
    ls.remove('loggedIn');
    ls.remove('user');
    setLoggedIn(false);
  }
  return (
    <div className="container mx-auto px-4">
      {!loggedIn ? <Login login={() => loginUser()} /> :
      <BrowserRouter>
        <Routes>
          <Route index element={<Dashboard logout={() => logoutUser()} />} />
          <Route path="/" element={<Dashboard logout={() => logoutUser()} />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/storage" element={<Storage logout={() => logoutUser()} />}></Route>
          <Route path="/:id" element={<IdPage logout={() => logoutUser()} />}></Route>
        </Routes>
      </BrowserRouter>
      }
    </div>
  );
}

export default App;
