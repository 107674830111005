import React, {useEffect, useState} from 'react'
import Header from '../Components/Header';
import {useParams} from "react-router-dom";
import axios from 'axios';
import Config from '../Config.json';

const Id = (props) => {
    const [results, setResults] = useState(false);
    let { id } = useParams();
    //const theRequester = ls.get('user');
    useEffect(() => {
        axios.get(`${Config.APIURL}/getanitem/${id}`)
        .then(response => {
            console.log(response);
            setResults(response.data.item);
        })
        .catch (error => {
        console.log(error);
        setResults(false);
        })
    }, [id])
  return (
    <div className='container'>
        <Header logout={props.logout} />
        <div className='body rounded mt-5 flex flex-col justify-center items-center lg:flex lg:flex-row lg:justify-between lg:items-center '>
            <div>
                {!results ? 
                    <div className="">در حال دریافت...</div>
                :
                <div className="results mt-5">
                {results ? 
                <>
                <div className="border rounded bg-light-100 p-3">
                    <div className="flex flex-row justify-between items-baseline text-center">
                        <div>
                            نام <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.Name}</p>
                        </div>
                        <div>
                            نوع <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.Type}</p>
                        </div>
                        <div>
                            شناسه ملی <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.NationalCode}</p>
                        </div>
                        <div>
                            شماره ثبت <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.RegisterNumber}</p>
                        </div>
                        <div>
                            وضعیت <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.Status}</p>
                        </div>
                        <div>
                            کدپستی <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.PostCode}</p>
                        </div>
                        <div>
                            تعداد اعضای اصلی <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.DirectMembers}</p>
                        </div>
                    </div>
                </div>
                <div className="overflow-x-auto relative">
                    <table className="table-auto border-collapse border border-slate-500 mt-5 rounded w-full text-sm">
                        <thead>
                            <tr>
                                <th className="border p-2">نام/عنوان</th>
                                <th className="border p-2">نوع</th>
                                <th className="border p-2">نام</th>
                                <th className="border p-2">نام خانوادگی</th>
                                <th className="border p-2">نام پدر</th>
                                <th className="border p-2">تاریخ تولد</th>
                                <th className="border p-2">شماره شناسنامه</th>
                                <th className="border p-2">شماره/شناسه ملی</th>
                                <th className="border p-2">تلفن همراه</th>
                                <th className="border p-2">کدپستی</th>
                                <th className="border p-2">جنسیت</th>
                                <th className="border p-2">وضعیت</th>
                                <th className="border p-2">سمت ها</th>
                                <th className="border p-2">سهام</th>
                            </tr>
                        </thead>
                        <tbody>
                        {results.Members.map(member => (
                            <tr key={member.Id} className="hover:bg-gray-100">
                                <td className="border p-2">{member.FullName}</td>
                                <td className="border p-2">{member.PersonType === 2 ? 'حقوقی' : 'حقیقی'}</td>
                                <td className="border p-2">{member.FirstNameFA}</td>
                                <td className="border p-2">{member.LastNameFA}</td>
                                <td className="border p-2">{member.FatherNameFA}</td>
                                <td className="border p-2">{member.BirthDateSH}</td>
                                <td className="border p-2">{member.IdentityNo}</td>
                                <td className="border p-2">{member.NationalityCode}</td>
                                <td className="border p-2">{member.MobileNumber4SMS}</td>
                                <td className="border p-2">{member.PostCode}</td>
                                <td className="border p-2">{member.Sex === 1 && 'خانم'}{member.Sex === 2 && 'آقا'} {member.Sex === 0 && 'حقوقی'}</td>
                                <td className="border p-2">{member.State === 1 ? 'فعال' : 'غیرفعال'}</td>
                                <td className="border">
                                    <table className="table-auto border-collapse border border-slate-500 rounded">
                                        <thead>
                                            <tr>
                                                <th className="border p-2">عنوان</th>
                                                <th className="border p-2">شروع</th>
                                                <th className="border p-2">مدت</th>
                                                <th className="border p-2">اعتبار</th>
                                                <th className="border p-2">پایان</th>
                                                <th className="border p-2">امضا</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {member.Positions.map(position => (
                                                <tr key={position.Id}>
                                                    <td className="border p-2">{position.Title}</td>
                                                    <td className="border p-2">{position.StartDate}</td>
                                                    <td className="border p-2">{position.PeriodTime}</td>
                                                    <td className="border p-2">{position.EndDateValidity}</td>
                                                    <td className="border p-2">{position.EndDate}</td>
                                                    <td className="border p-2">{position.SignatureState}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                {member.Stocks &&
                                <td className="border">
                                    <table className="table-auto border-collapse border border-slate-500 rounded">
                                        <thead>
                                            <tr>
                                                <th className="border p-2">عنوان</th>
                                                <th className="border p-2">درصد</th>
                                                <th className="border p-2">تعداد سهام</th>
                                                <th className="border p-2">ارزش سهام</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {member.Stocks.map(stocks => (
                                                <tr key={stocks.Id}>
                                                    <td className="border p-2">{stocks.Title}</td>
                                                    <td className="border p-2">{stocks.PercentStocks}</td>
                                                    <td className="border p-2">{stocks.NumberOfStocks}</td>
                                                    <td className="border p-2">{stocks.Amount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                }
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                </>
                : ""
                }
            </div>
                }
            </div>
        </div>
    </div>
  )
}

export default Id