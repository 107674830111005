import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ls from 'local-storage';
import Config from '../Config.json';
import {useLocation, Link} from "react-router-dom";
import { FiUser, FiLogOut, FiDollarSign, FiHome } from 'react-icons/fi';

const Header = (props) => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState('null');
    const location = useLocation ();
    const fetchData = async () => {
        const theUser = ls.get('user');
        setLoading(true);
        await axios.get(`${Config.APIURL}/getuser/${theUser}`)
        .then (response => {
            setUser(response.data.user);
            setLoading(false);
        })
        .catch (error => {
            console.log(error);
            setLoading(false);
        });
    }
    useEffect (() => {
        fetchData();
    }, []);
    const logoutButton = () => {
        props.logout();
    }
  return (
    <div className='header border rounded flex-col w-full justify-center items-center p-3 mt-3 md:flex md:flex-row md:justify-between md:items-center md:p-3 md:mt-3'>
        <div className='flex flex-col items-start w-full mb-1 md:w-52'>
            <div className='flex flex-row items-center justify-between w-full'>
                <div className='flex flex-row items-center justify-center bg-blue-100 text-blue-500 p-3 rounded w-1/4'>
                    <FiUser size="17" />
                </div>
                <div className='flex flex-row items-center justify-center bg-blue-200 text-blue-700 p-2 rounded mr-1 w-3/4'>
                    {loading ? 
                        <div className='animate-pulse'>
                            درحال دریافت ...
                        </div>
                        :
                        <div>{user.firstName} {user.lastName}</div>
                    }
                </div>
            </div>
            <div className='mt-1 flex flex-row items-center justify-center bg-red-100 p-1 rounded w-full'>
                <button className="flex flex-row items-center justify-center  text-red-500  w-full" onClick={logoutButton}>
                    <FiLogOut style={{color: "text-red-900"}} size="16" /> خروج
                </button>
            </div>
        </div>
        {location.pathname !== '/' && (
            <div className="flex flex-row items-center justify-center w-full mb-1 md:w-64">
                <Link to='/' className="flex flex-row-reverse  items-center justify-center">برگشت به صفحه اصلی <FiHome className='mx-3' /></Link>
            </div>
        )}
        <div className='flex flex-col items-end w-full mt-3 md:w-24 md:mt-0'>
            <div className='flex flex-row items-center justify-center bg-yellow-500 text-yellow-900 px-3 py-1 rounded shadow w-full mb-1'>
                <FiDollarSign /> اعتبار
            </div>
            <div className='text-center bg-yellow-100 text-yellow-700 px-2 py-2 rounded w-full'>
                {loading ? 
                    <div className='animate-pulse'>
                        ---
                    </div>
                    :
                    <>
                    <div className='text-sm'>
                        {user.credit}
                    </div>
                    <div>
                        <button className='text-xs' onClick={fetchData}>بروزرسانی</button>
                    </div>
                    </>
                }
            </div>
        </div>
    </div>
  )
}

export default Header