import React, { useState } from 'react';
import {Link} from "react-router-dom";
import Header from '../Components/Header';
import ls from 'local-storage';
import { FiSearch, FiArrowLeft, FiLoader, FiInfo, FiAlertTriangle, FiCloudLightning, FiDatabase } from 'react-icons/fi';
import axios from 'axios';
import Config from '../Config.json';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Dashboard = (props) => {
    const [showError, setShowError] = useState(false);
    const [genError, setGenError] = useState(false);
    const [generated, setGenerated] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showGenLoading, setShowGenLoading] = useState(false);
    const [genResults, setGenResults] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [results, setResults] = useState(false);
    const [saveBtnText, setSaveBtnText] = useState('ذخیره در پایگاه داده');
    const theRequester = ls.get('user');

    const searchByNationalId = async () => {
        const nationalId = document.querySelector('#nationalId').value;
        if (nationalId.length < 11) {
            setShowError(true);
        } else {
            setSaveBtnText('ذخیره در پایگاه داده');
            setResults(false);
            setShowError(false);
            setShowLoading(true);
            setDisableBtn(true);
            await axios.get(`${Config.APIURL}/getbynationalid/${theRequester}/${nationalId}`)
            .then(response => {
                setShowLoading(false);
                setDisableBtn(false);
                setShowError(false);
                console.log(response.data);
                setResults(response.data);
            })
            .catch (error => {
                setShowError(true);
                setDisableBtn(false);
                setShowLoading(false);
                setResults(false);
                console.log(error);
            })
        }
    }
    const hideError = async () => {
        setShowError(false);
    }
    const saveItem = async () => {
        await axios.post(`${Config.APIURL}/saveitem/${theRequester}`, results)
        .then(response => {
            console.log('Item Saved!');
            setSaveBtnText('ذخیره شد!');
        })
        .catch (error => {
            setSaveBtnText('خطایی پیش امده!');
        })
    };
        
    const pdfExport = (nationalcode) => {
        const input = document.getElementById('company');
            html2canvas(input, {
              scale: 0.5,
              width: input.width,
              height: input.height
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save(nationalcode+".pdf");
            });
      }

    const generateNew = async () => {
        setGenError(false)
        setShowGenLoading(true)
        const digits = document.querySelector('#digits').value;
        const coType = document.querySelector('#cotype').value;
        await axios.get(`${Config.APIURL}/generate/${theRequester}/${digits}/${coType}`)
        .then(response => {
            setGenerated(true)
            setShowGenLoading(false)
            const generatedItem = {
                NationalCode: response.data.nationalCode,
                CompanyName: response.data.details.Company.Name,
                CompanyType: response.data.details.Company.Type,
            }
            setGenResults(generatedItem);
        })
        .catch (error => {
            setShowGenLoading(false)
            setGenerated(false);
            setGenError(true);
        })
    };
  return (
    <div className='container'>
        <Header logout={props.logout} />
        <div className='body rounded mt-5 flex flex-col justify-center items-center lg:flex lg:flex-row lg:justify-between lg:items-center '>
            {showError && 
                <div className='container px-4 flex flex-row items-center justify-center absolute bottom-6 w-full'>
                        <div className='flex flex-row items-center bg-red-100 text-red-500 p-3 rounded mt-1 w-full'>
                            <FiAlertTriangle className='mx-1' size="26" />
                            خطائی رخ داده است!
                            <button className='absolute left-6 rounded bg-red-200 py-1 px-3' onClick={hideError}>
                                بستن
                            </button>
                        </div>
                </div>
            }
            <div className='flex flex-col items-center justify-center bg-green-100 w-full rounded p-6 mb-3 lg:mb-1'>
                <FiSearch className='mb-1 text-green-700' size="30" />
                <h2 className='mb-5 font-bold text-green-700'>جستجو با شناسه ملی</h2>
                <div className='flex flex-row w-3/4 justify-between items-center bg-white h-12 border rounded'>
                    <input type="text" id="nationalId" className={!disableBtn ? "flex h-11 w-5/6 p-5 focus:outline-none" : ' cursor-not-allowed h-11 flex 2-5/6 p-5'} disabled={disableBtn ? true : false} />
                    <button onClick={searchByNationalId} className={!disableBtn ? 'flex justify-center items-center h-12 text-green-700 w-1/6' : 'hidden'}>
                        <FiArrowLeft width={22} />
                    </button>
                    {showLoading && 
                    <div className='flex justify-center items-center h-12 text-green-700 w-1/6 animate-spin'>
                        <FiLoader className='hover:cursor-not-allowed' />
                    </div>
                    }
                </div>
                <div className='flex flex-row justify-center items-center text-xs text-green-600 mt-5'>
                <FiInfo className='mx-1' size="14" />
                    با هر جستجوی صحیح، یک اعتبار کسر خواهد شد.
                </div>
            </div>
            <div className='flex flex-col items-center justify-center bg-purple-100 w-full rounded p-6 mb-3 lg:mb-1 lg:mx-1'>
                <FiCloudLightning className='mb-1 text-purple-700' size="30" />
                <h2 className='mb-5 font-bold text-purple-700'>تولید شناسه ملی و جستجوی شرکت</h2>
                <div className='h-12 flex flex-row justify-between items-center text-sm'>
                    <div className='preNumber'>
                        <select id="digits" className="bg-white h-12 border rounded  text-gray-700">
                            <option disabled selected>پیش‌شماره</option>
                            <option value='1401'>1401</option>
                            <option value='1400'>1400</option>
                            <option value='1010'>1010</option>
                            <option value='1030'>1030</option>
                        </select>
                    </div>
                    <div className="coType">
                        <select id="cotype" className="bg-white h-12 border rounded  text-gray-700">
                            <option disabled selected>نوع شرکت</option>
                            <option  value='any'>همه</option>
                            <option value='002'>سهامی خاص</option>
                            <option value='008'>تعاونی</option>
                        </select>
                    </div>
                    <div className="flex flex-row justify-center items-center bg-purple-700 text-white h-12 border border-purple-700 rounded mx-1 w-16" onClick={generateNew}>
                        {showGenLoading ?
                        <FiLoader className='hover:cursor-not-allowed animate-spin' />
                        :
                        <FiArrowLeft width={22} />
                        }
                    </div>
                    
                </div>
                <div className='flex flex-row justify-center items-center text-xs text-purple-600 mt-5'>
                    <FiInfo className='mx-1' size="14" />
                    در صورت وجود شناسه ملی و ذخیره آن در داده‌ها، یک اعتبار کسر می‌گردد.
                </div>
            </div>
            <div className='flex flex-col items-center justify-center bg-pink-100 w-full rounded p-6 mb-3 lg:mb-1'>
                <FiDatabase className='mb-1 text-pink-700' size="30" />
                <h2 className='mb-5 font-bold text-pink-700'>اطلاعات ذخیره شده</h2>
                <div className='h-12 flex flex-row justify-between items-center text-sm'>
                    <Link to='/storage'>
                        <button className='flex justify-center items-center h-12 text-pink-700'>
                            مشاهده داده‌های ذخیره شده
                        </button>
                    </Link>
                </div>
                <div className='flex flex-row justify-center items-center text-xs text-pink-600 mt-5'>
                    <FiInfo className='mx-1' size="14" />
                    اطلاعات ذخیره شده اعتباری کسر نخواهند کرد.
                </div>
            </div>
        </div>
        <div className="results mt-5">
            {results ? 
            <>
            <div className='flex flex-row items-center justify-center mb-4'>
                <button className='bg-blue-300 w-1/2 ml-4 p-3' onClick={saveItem}>
                    {saveBtnText}
                </button>
{/*                 <button className='bg-green-500 w-1/2 mr-4 p-3' onClick={() => pdfExport(results.Company.NationalCode)}>
                    ذخیره تصویر
                </button> */}
            </div>
            <div id="company" className='text-xs'>
            <div className="border rounded bg-light-100 p-3">
                <div className="flex flex-row justify-between items-baseline text-center">
                    <div>
                        نام <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.Name}</p>
                    </div>
                    <div>
                        نوع <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.Type}</p>
                    </div>
                    <div>
                        شناسه ملی <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.NationalCode}</p>
                    </div>
                    <div>
                        شماره ثبت <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.RegisterNumber}</p>
                    </div>
                    <div>
                        وضعیت <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.Status}</p>
                    </div>
                    <div>
                        کدپستی <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.PostCode}</p>
                    </div>
                    <div>
                        تعداد اعضای اصلی <p className="text-gray-800 font-bold text-lg pt-3">{results.Company.DirectMembers}</p>
                    </div>
                </div>
            </div>
            <div className="overflow-x-auto relative">
                    <table className="table-auto border-collapse border border-slate-500 mt-5 rounded w-full text-sm">
                        <thead>
                            <tr>
                                <th className="border p-2">نام/عنوان</th>
                                <th className="border p-2">نوع</th>
                                <th className="border p-2">نام</th>
                                <th className="border p-2">نام خانوادگی</th>
                                <th className="border p-2">نام پدر</th>
                                <th className="border p-2">تاریخ تولد</th>
                                <th className="border p-2">شماره شناسنامه</th>
                                <th className="border p-2">شماره/شناسه ملی</th>
                                <th className="border p-2">تلفن همراه</th>
                                <th className="border p-2">کدپستی</th>
                                <th className="border p-2">جنسیت</th>
                                <th className="border p-2">وضعیت</th>
                                <th className="border p-2">سمت ها</th>
                                <th className="border p-2">سهام</th>
                            </tr>
                        </thead>
                        <tbody>
                        {results.Members.map(member => (
                            <tr key={member.Id} className="hover:bg-gray-100">
                                <td className="border p-2">{member.FullName}</td>
                                <td className="border p-2">{member.PersonType === 2 ? 'حقوقی' : 'حقیقی'}</td>
                                <td className="border p-2">{member.FirstNameFA}</td>
                                <td className="border p-2">{member.LastNameFA}</td>
                                <td className="border p-2">{member.FatherNameFA}</td>
                                <td className="border p-2">{member.BirthDateSH}</td>
                                <td className="border p-2">{member.IdentityNo}</td>
                                <td className="border p-2">{member.NationalityCode}</td>
                                <td className="border p-2">{member.MobileNumber4SMS}</td>
                                <td className="border p-2">{member.PostCode}</td>
                                <td className="border p-2">{member.Sex === 1 && 'خانم'}{member.Sex === 2 && 'آقا'} {member.Sex === 0 && 'حقوقی'}</td>
                                <td className="border p-2">{member.State === 1 ? 'فعال' : 'غیرفعال'}</td>
                                <td className="border">
                                    <table className="table-auto border-collapse border border-slate-500 rounded">
                                        <thead>
                                            <tr>
                                                <th className="border p-2">عنوان</th>
                                                <th className="border p-2">شروع</th>
                                                <th className="border p-2">مدت</th>
                                                <th className="border p-2">اعتبار</th>
                                                <th className="border p-2">پایان</th>
                                                <th className="border p-2">امضا</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {member.Positions.map(position => (
                                                <tr key={position.Id}>
                                                    <td className="border p-2">{position.Title}</td>
                                                    <td className="border p-2">{position.StartDate}</td>
                                                    <td className="border p-2">{position.PeriodTime}</td>
                                                    <td className="border p-2">{position.EndDateValidity}</td>
                                                    <td className="border p-2">{position.EndDate}</td>
                                                    <td className="border p-2">{position.SignatureState}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                {member.Stocks &&
                                <td className="border">
                                    <table className="table-auto border-collapse border border-slate-500 rounded">
                                        <thead>
                                            <tr>
                                                <th className="border p-2">عنوان</th>
                                                <th className="border p-2">درصد</th>
                                                <th className="border p-2">تعداد سهام</th>
                                                <th className="border p-2">ارزش سهام</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {member.Stocks.map(stocks => (
                                                <tr key={stocks.Id}>
                                                    <td className="border p-2">{stocks.Title}</td>
                                                    <td className="border p-2">{stocks.PercentStocks}</td>
                                                    <td className="border p-2">{stocks.NumberOfStocks}</td>
                                                    <td className="border p-2">{stocks.Amount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                                }
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                </div>
            </>
            : ""
            }
            {generated ?
            <div className='bg-green-100 border border-1 boorder-radius rounded border-green-200 p-3'>
                <div className='text-green-800 font-bold mb-3'>
                    عملیات موفق
                </div>
                <div className='text-green-800 font-bold'>شرک جدیدی با اطلاعات زیر در داده‌ها ذخیره شد:</div>
                <div className='text-green-800 font-light'>
                    <p>← {genResults.NationalCode}</p>
                    <p>← {genResults.CompanyName}</p>
                    <p>← {genResults.CompanyType}</p>
                </div>
            </div>
            :
            <div></div>
            }
            {genError ?
            <div className='bg-red-100 border border-1 boorder-radius rounded border-red-200 p-3'>
                <div className='text-red-800 font-bold mb-3'>
                    خطا در هنگام عملیات
                </div>
                <div className='text-red-800 font-bold'>
                    عملیات به یکی از دلایل زیر با خطا مواجه شد:
                </div>
                <div className='text-red-800 font-light'>
                    <p>	← شناسه ملی تولید شده در سامانه ثبت وجود ندارد!</p>
                    <p>	← شناسه تولیدی قبلا ذخیره شده است!</p>
                    <p>	← نوع شرکت انتخابی با شرکت یافت شده همخوانی ندارد!</p>
                    <p>	← اعتبار کافی برای انجام این کار را ندارید!</p>
                </div>
            </div>
            :
            <div></div>
            }
        </div>
    </div>
  )
}

export default Dashboard