import React, {useState} from 'react'
import axios from 'axios';
import ls from 'local-storage';
import Config from '../Config.json';

const Login = (props) => {
    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const checkCredentials = () => {
        setShowError(false);
        setShowLoading(true);
        const username = document.querySelector('#usernameInput').value;
        const password = document.querySelector('#passwordInput').value;
        if (!username || !password) {
            setShowError(true);
            setShowLoading(false);
            return
        }
        axios.post(`${Config.APIURL}/authuser`, {phoneNumber: username, password: password})
        .then(response => {
            console.log(`Logged in With: ${username}`);
            ls.set('user', username);
            props.login();
        })
        .catch (error => {
            setShowError(true);
            setShowLoading(false);
        })
    }
  return (
    <div className=" flex flex-col justify-center items-center align-content-center h-screen">
        <h1 className='flex justify-center items-center p-1 mb-5 font-bold'>ورود به سامانه</h1>
        {showError && <div className='text-center p-1 rounded mb-5 bg-red-100 text-red-700'>خطا در هنگام ورود</div>}
        {showLoading && <div className='text-center bg-blue-100 text-blue-700'>در حال بررسی... </div>}
        <div className='flex flex-col'>
            <input type="text" id="usernameInput" className='text-right p-1 border rounded mb-3' placeholder="نام کاربری" />
            <input type="password" id="passwordInput" className='text-right p-1 border rounded mb-3' placeholder="گذرواژه" />
            <button onClick={checkCredentials} className="bg-blue-100 rounded text-blue-800 p-1">ورود به پنل کاربری</button>
        </div>
    </div>
  )
}

export default Login